export const translations = {
  pl: {
    digitalManagement:
      'Zarządzanie treściami audiowizualnymi <br />  dla produkcji filmowych',
    digitalManagementMessage:
      'Fisheye to wielofunkcyjna platforma do gromadzenia, przechowywania, procesowania, katalogowania i dystrybucji materiałów audiowizualnych.',
    dataNaviation: 'Gładka nawigacja <br /> w oceanach danych',
    dataNaviationMessage:
      'W teorii produkcja filmowa to linearny i uporządkowany proces. W rzeczywistości jest ona dużo bardziej chaotyczna i rozproszona. Stworzyliśmy Fisheye, by uporządkować i ułatwić procesy i komunikację przy produkcji filmowej. Nasze rozwiązanie pozwala połączyć całe dane generowane podczas produkcji filmu ze wszystkimi w nią zaangażowanymi stronami. Fisheye łączy kilka narzędzi, które w innym wypadku wymagają osobnych platform i niezależnych subskrypcji. Oto jest, wszystko w jednym, kompleksowo i sprawnie.',
    demo: 'Wypróbuj konto demo',
    demoMessage:
      'Jeśli chcesz przetestować funkcjonalności naszej platformy, prosimy o kontakt w sprawie konta demo.',
    disclaimer:
      'FISHEYE SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ realizuje projekt dofinansowany z Funduszy Europejskich „FISHEYE – wdrożenie na rynek wielofunkcyjnej platformy do gromadzenia, archiwizacji, przetwarzania, katalogowanie i dystrybucji treści audiowizualnych.” Celem projektu jest wprowadzenie na rynek krajowy i zagraniczny innowacji produktowej: wielofunkcyjnej platformy do gromadzenia, archiwizacji, przetwarzania, katalogowania i dystrybucji treści audiowizualnych. Produkt dedykowany na profesjonalny rynek tworzenia i obróbki form multimedialnych. Dofinansowanie projektu z UE: 999 175.00 PLN Wartość projektu 1 323 983,40 PLN. © Fisheye sp. z o.o. 2023',
    input: 'Wpisz swój email',
    button: 'Wyślij',
    startPack: '<h2>Pakiet START</h2>',
    enterprisePack: '<h2>Pakiet ENTERPISE</h2>',
    archivePack: '<h2>Archiwizacja Danych</h2>',
    startPackPrice: '<span class="tall">49 zł</span> netto / użytkownik',
    enterprisePackPrice: 'Skontaktuj się z nami',
    archivePackPrice: 'Skontaktuj się z nami',
    startPackFeatures: '<ul><li>Do 3 użytkowników</li><li>Do 1 GB danych</li><li>Do 2 projektów</li></ul>',
    enterprisePackFeatures: '<ul><li>Nielimitowana liczba projektów</li><li>Nielimitowana liczba użytkowników</li><li>Zarządzanie dostępami</li><li>Tworzenie i udostępnianie playlist</li><li>Tagowanie ujęć</li><li>Udostępnianie linków</li></ul>',
    archivePackFeatures: '<ul><li>Zabezpieczenie danych w chmurze</li><li>Archiwizacja na kasetach LTO</li></ul>',
  },
  en: {
    digitalManagement: 'Digital Asset Management <br /> For Film Production',
    digitalManagementMessage:
      'Fisheye is a multifunctional platform for collecting, archiving, processing, cataloging and distributing audiovisual content.',
    dataNaviation: 'Smoothly navigating oceans <br /> of data',
    dataNaviationMessage:
      'In theory, film production is a linear and streamlined process. In reality it is much more chaotic and dispersed. Fisheye was designed to make workflows and communication as smooth as possible. Our solution allows you to connect all data generated during film production with all parties involved in the process. It combines several tools that would otherwise require separate platforms and subscriptions, that work independently. Here it is, all in one, comprehensive and smooth.',
    demo: 'Book a demo',
    demoMessage:
      'If you would like to test our platform features please contact us for a demo account.',
    disclaimer:
      'FISHEYE LLC implements a co-financed project from European Funds: „FISHEYE - market implementation of a multifunctional platform for collecting, archiving, processing, cataloging and distributing audiovisual content”. The aim of the project is to introduce a product innovation to the domestic and foreign market: a multifunctional platform for collecting, archiving, processing, cataloging and distributing audiovisual content. A product dedicated to the professional market of creating and processing multimedia content. EU funding for the project: PLN 999,175.00 Project value: PLN 1,323,983.40. © Fisheye sp. z o.o. 2023',
    input: 'Enter your email',
    button: 'Send',
    startPack: '<h2>STARTER PLAN</h2>',
    enterprisePack: '<h2>ENTERPISE</h2>',
    archivePack: '<h2>Data Archiving</h2>',
    startPackPrice: '<span class="tall">49 PLN</span> / user',
    enterprisePackPrice: 'Contact us',
    archivePackPrice: 'Contact us',
    startPackFeatures: '<ul><li>Up to 3 users</li><li>1 GB storage</li><li>Up to 2 projects</li></ul>',
    enterprisePackFeatures: '<ul><li>Unlimited projects</li><li>Unlimited users</li><li>Share permissions</li><li>Playlists sharing</li><li>Clips tagging</li><li>Links sharing</li></ul>',
    archivePackFeatures: '<ul><li>Backup in cloud</li><li>LTO Archiving</li></ul>',
  },
};
