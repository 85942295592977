import React from 'react';
import { translations } from "../translations";

export const PricesTable = ({ language }) => {
    return (
        <>
            <table className="prices-table">
                <thead>
                <tr>
                    <th>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPack }}/>
                    </th>
                    <th>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePack }}/>
                    </th>
                    <th>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePack }}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <p className="logo-content__main-text hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPackPrice }}/>
                    </td>
                    <td>
                        <p className="logo-content__main-text hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePackPrice }}/>
                    </td>
                    <td>
                        <p className="logo-content__main-text hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePackPrice }}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPackFeatures }}/>
                    </td>
                    <td>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePackFeatures }}/>
                    </td>
                    <td>
                        <p className="logo-content__main-text hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePackFeatures }}/>

                    </td>
                </tr>
                </tbody>
            </table>
            <table className="prices-table-mobile">
                <thead>
                <tr>
                    <th>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPack }}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <p className="hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPackPrice }}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].startPackFeatures }}/>
                    </td>
                </tr>
                </tbody>
            </table>

            <table className="prices-table-mobile">
                <thead>
                <tr>
                    <th>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePack }}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <p className="hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePackPrice }}/>
                    </td>
                </tr>
                <tr>

                    <td>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].enterprisePackFeatures }}/>
                    </td>

                </tr>
                </tbody>
            </table>

            <table className="prices-table-mobile">
                <thead>
                <tr>
                    <th>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePack }}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <p className="hand-text text-centered"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePackPrice }}/>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p className="hand-text"
                           dangerouslySetInnerHTML={{ __html: translations[language].archivePackFeatures }}/>

                    </td>
                </tr>
                </tbody>
            </table>
        </>
    );
};
