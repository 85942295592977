import React from 'react';
import './App.css';
import logo from './assets/fisheye_logo.svg';
import laptop from './assets/laptop.svg';
import europeanLogo from './assets/European_Funds_logos.png';
import instagram from './assets/instagram_1384015.svg';
import facebook from './assets/facebook_2168281.svg';
import linkedin from './assets/linkedin_1384014.svg';
import youtube from './assets/youtube_1384012.svg';
import monitor from './assets/monitor.png';
import inquiry from './assets/Fisheye-zapytanie-0000.png';
import hand from './assets/hand.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLanguage } from './contexts/LanguageContext';
import { translations } from './translations';
import { useForm } from '@formspree/react';
import { enqueueSnackbar } from 'notistack';
import { PricesTable } from "./components/PricesTable";

function App() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const { toggleLanguage, language } = useLanguage();
    const [email, setEmail] = React.useState('');
    const isLanguageActive = (lang) => lang === language;

    const handleLanguageClick = (lang) => {
        if (isLanguageActive(lang)) {
            toggleLanguage(lang);
        }
    };

    const [state, handleSubmit] = useForm('xbjvqley');
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        handleSubmit(e);
        enqueueSnackbar('Thanks for joining!', {
            variant: 'success',
            preventDuplicate: true,
        });
        setEmail('');
    };

    return (
        <div className="header">
            <nav className="navigation">
                <div className="navigation__left-side">
                    <img src={logo} alt="logo" className="logo-image"/>
                </div>
                <div className="navigation__right-side">
                    <div
                        onClick={() => {
                            toggleLanguage();
                            handleLanguageClick('en');
                        }}
                        className={`language ${isLanguageActive('en') ? 'active' : ''} en`}
                    >
                        en
                    </div>
                    <div
                        onClick={() => {
                            toggleLanguage();
                            handleLanguageClick('pl');
                        }}
                        className={`language ${isLanguageActive('pl') ? 'active' : ''}`}
                    >
                        pl
                    </div>
                </div>
            </nav>
            <Slider {...settings} className="main-content">
                <div className="logo-content first-slide">
                    <img
                        src={laptop}
                        alt="laptop-logo"
                        className="main-logo first-slide"
                    />
                    <p
                        className="text-content first-slide"
                        dangerouslySetInnerHTML={{
                            __html: translations[language].digitalManagement,
                        }}
                    ></p>
                    <p className="hand-text first-slide">
                        {translations[language].digitalManagementMessage}
                    </p>
                </div>
                <div className="logo-content">
                    <img
                        src={hand}
                        alt="hand-logo"
                        className="main-logo main-logo__hand"
                    />
                    <p
                        className="text-content hand-logo"
                        dangerouslySetInnerHTML={{
                            __html: translations[language].dataNaviation,
                        }}
                    ></p>
                    <p className="hand-text hand-text__modify">
                        {translations[language].dataNaviationMessage}
                    </p>
                </div>
                <div className="logo-content logo-content__monitor ">
                    <div className="logo-content__main">
                        <div className="image-container">
                            <img
                                src={monitor}
                                alt="contact-logo"
                                className="main-logo monitor-logo"
                            />
                        </div>
                        <div className="logo-content__main-container">
                            <h1 className="logo-content__main-header text-content">
                                {translations[language].demo}
                            </h1>
                            <p className="logo-content__main-text hand-text">
                                {translations[language].demoMessage}
                            </p>
                        </div>
                    </div>
                    <div className="logo-content__contact">
                        <form onSubmit={handleFormSubmit}>
                            <input
                                className="logo-content__contact-input"
                                placeholder={`${translations[language].input}`}
                                type="email"
                                id="emial"
                                name="emial"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <button
                                type="submit"
                                disabled={state.submitting}
                                className="logo-content__contact-button"
                            >
                                {translations[language].button}
                            </button>
                        </form>
                    </div>
                </div>
                <div className="logo-content">
                    <h1 className="hand-text">Cennik</h1>
                    <div className="logo-content__main centered">
                        <PricesTable language={language}/>
                    </div>
                </div>
                <div className="logo-content">
                    <img
                        src={inquiry}
                        alt="fisheye-inquiry"
                    />
                </div>
            </Slider>
            <footer className="footer-section">
                <div className="footer-image">
                    <img src={europeanLogo} alt="european-logo" className="footer-logo"/>
                </div>
                <p className="footer-text">{translations[language].disclaimer}</p>
                <div className="footer-socials">
                    <a href="https://instagram.com/fisheye.poland?igshid=MzRlODBiNWFlZA==">
                        <img
                            src={instagram}
                            alt="instagram-logo"
                            className="instagram-logo logo"
                        />
                    </a>
                    <a href="https://www.facebook.com/fisheye.poland">
                        <img
                            src={facebook}
                            alt="facebook-logo"
                            className="facebook-logo logo"
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/fisheyepoland/">
                        <img
                            src={linkedin}
                            alt="linkedin-logo"
                            className="linkedin-logo logo"
                        />
                    </a>
                    <a href="https://www.youtube.com/channel/UC7u5-vyclGwP5U00pQ5Im_g">
                        <img
                            src={youtube}
                            alt="youtube-logo"
                            className="youtube-logo logo"
                        />
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default App;
